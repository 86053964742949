import { ArrowLeftIcon } from '@expo/styleguide-icons/outline/ArrowLeftIcon';
import { AtSignIcon } from '@expo/styleguide-icons/outline/AtSignIcon';
import { Link03Icon } from '@expo/styleguide-icons/outline/Link03Icon';
import { Lock01Icon } from '@expo/styleguide-icons/outline/Lock01Icon';
import { LogOut03Icon } from '@expo/styleguide-icons/outline/LogOut03Icon';
import { Mail01Icon } from '@expo/styleguide-icons/outline/Mail01Icon';
import { PasscodeLockIcon } from '@expo/styleguide-icons/outline/PasscodeLockIcon';
import { Trash03Icon } from '@expo/styleguide-icons/outline/Trash03Icon';
import { UserCircleIcon } from '@expo/styleguide-icons/outline/UserCircleIcon';
import { Users01Icon } from '@expo/styleguide-icons/outline/Users01Icon';

import { SidebarItem, UserSidebarItem } from '~/ui/components/SidebarNavigation/helpers';

import { SidebarLink } from '../Sidebar/SidebarLink';
import { SidebarSectionHeader } from '../Sidebar/SidebarSectionHeader';

type UserSettingsProps = {
  onClick?: () => void;
  urlAccountName?: string;
  isSSOUser?: boolean;
  selectedSidebarItem?: SidebarItem;
  selectedUserSidebarItem?: UserSidebarItem;
};

export function UserSettings({
  onClick,
  urlAccountName,
  isSSOUser,
  selectedSidebarItem,
  selectedUserSidebarItem,
}: UserSettingsProps) {
  function isSelected(item: UserSidebarItem) {
    return (selectedUserSidebarItem ?? selectedSidebarItem) === item;
  }

  return (
    <>
      <SidebarLink
        title="Back to Dashboard"
        Icon={ArrowLeftIcon}
        href={`/accounts/${urlAccountName}`}
        isSelected={false} // When user clicks it then it will be handled elsewhere.
        onClick={onClick}
      />
      <SidebarSectionHeader title="Settings" />
      <SidebarLink
        title="About you"
        href="/settings/#profile"
        Icon={UserCircleIcon}
        isSelected={isSelected(UserSidebarItem.USER_SETTINGS_ABOUT_YOU)}
        onClick={onClick}
      />
      {!isSSOUser && (
        <>
          <SidebarLink
            title="Email"
            href="/settings/#email"
            Icon={Mail01Icon}
            isSelected={isSelected(UserSidebarItem.USER_SETTINGS_EMAIL)}
            onClick={onClick}
          />
          <SidebarLink
            title="Change password"
            href="/settings/#password"
            Icon={Lock01Icon}
            isSelected={isSelected(UserSidebarItem.USER_SETTINGS_CHANGE_PASSWORD)}
            onClick={onClick}
          />
          <SidebarLink
            title="Two-factor authentication"
            href="/settings/#two-factor-auth"
            Icon={PasscodeLockIcon}
            isSelected={isSelected(UserSidebarItem.USER_SETTINGS_TWO_FACTOR_AUTH)}
            onClick={onClick}
          />
        </>
      )}
      <SidebarLink
        title="Connections"
        href="/settings/#connections"
        Icon={Link03Icon}
        isSelected={isSelected(UserSidebarItem.USER_SETTINGS_CONNECTIONS)}
        onClick={onClick}
      />
      {!isSSOUser && (
        <SidebarLink
          title="Convert to organization"
          href="/settings/#convert-account"
          Icon={Users01Icon}
          isSelected={isSelected(UserSidebarItem.USER_SETTINGS_CONVERT_TO_ORG)}
          onClick={onClick}
        />
      )}
      <SidebarLink
        title="Rename account"
        href="/settings/#rename-account"
        Icon={AtSignIcon}
        isSelected={isSelected(UserSidebarItem.USER_SETTINGS_RENAME_ACCOUNT)}
        onClick={onClick}
      />
      <SidebarLink
        title="Log out of sessions"
        href="/settings/#delete-sessions"
        Icon={LogOut03Icon}
        isSelected={isSelected(UserSidebarItem.USER_SETTINGS_LOG_OUT_OF_SESSIONS)}
        onClick={onClick}
      />
      <SidebarLink
        title="Delete account"
        href="/settings/#delete-account"
        Icon={Trash03Icon}
        isSelected={isSelected(UserSidebarItem.USER_SETTINGS_DELETE_ACCOUNT)}
        onClick={onClick}
      />
    </>
  );
}
