import { DotsVerticalIcon } from '@expo/styleguide-icons/outline/DotsVerticalIcon';
import { useRef, useState } from 'react';

import { getUserDisplayName } from '~/common/helpers';
import { LoggedInProps } from '~/scenes/_app/helpers';
import { Avatar } from '~/ui/components/Avatar';
import { UserMenuContent } from '~/ui/components/Navigation/LoggedInNavigation/UserMenuContent';
import { useNavigationBlurHandler } from '~/ui/components/Navigation/LoggedInNavigation/helpers';
import { StaffBadge } from '~/ui/components/StaffBadge';
import { CALLOUT } from '~/ui/components/text';

import { Dropdown } from '../../Dropdown';

type Props = {
  currentUser: LoggedInProps['currentUser'];
};

export function UserDropdown({ currentUser }: Props) {
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);

  function onDismiss() {
    setIsVisible(false);
  }

  useNavigationBlurHandler(containerRef, onDismiss);

  return (
    <div className="flex flex-col justify-center border-t border-default p-2">
      <Dropdown
        className="min-w-[243px]"
        collisionPadding={{ left: 8, right: 8 }}
        sideOffset={-6}
        trigger={
          <div
            className="relative flex cursor-pointer items-center gap-3 rounded-md bg-default p-2 hocus:bg-element"
            data-testid="viewer-menu"
            onClick={() => {
              setIsVisible(!isVisible);
            }}>
            <Avatar
              name={currentUser?.firstName ? currentUser.firstName : currentUser.username}
              profilePhoto={currentUser?.profilePhoto}
              size="md"
              alt="User menu"
              className="shadow-xs"
            />
            {currentUser.isExpoAdmin && <StaffBadge className="bottom-2 left-1" />}
            <div className="flex flex-1 flex-col truncate">
              <CALLOUT weight="medium" className="truncate">
                {getUserDisplayName(currentUser)}
              </CALLOUT>
              <CALLOUT theme="secondary" className="truncate">
                {currentUser.bestContactEmail}
              </CALLOUT>
            </div>
            <DotsVerticalIcon className="icon-md mr-1" />
          </div>
        }>
        <UserMenuContent username={currentUser.username} isExpoAdmin={currentUser.isExpoAdmin} />
      </Dropdown>
    </div>
  );
}
