import { LoggedInProps } from '~/scenes/_app/helpers';
import {
  AccountDisabledBanner,
  AccountDisabledBannerProps,
} from '~/ui/components/Banners/AccountDisabledBanner';
import { useSelectedAccount } from '~/ui/components/SidebarNavigation/components/useSelectedAccount';

type Props = AccountDisabledBannerProps & {
  currentUser: LoggedInProps['currentUser'];
};

export function AccountDisabledMonitoring({ currentUser, ...rest }: Props) {
  const { selectedAccount } = useSelectedAccount(currentUser, currentUser?.accounts ?? []);

  if (!selectedAccount?.isDisabled) {
    return null;
  }

  return <AccountDisabledBanner {...rest} />;
}
