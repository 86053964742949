import { useRouter } from 'next/compat/router';

import { LoggedInProps } from '~/scenes/_app/helpers';
import { DocsButton } from '~/ui/components/Navigation/LoggedInNavigation/DocsButton';
import { Logo } from '~/ui/components/Navigation/Logo';
import { NotificationsList } from '~/ui/components/Navigation/Notification';

import { NavigationSelectors } from './NavigationSelectors';
import { SidebarLinks } from './SidebarLinks';
import { UserSettings } from './UserSettings';
import { UserDropdown } from './components/UserDropdown';
import { SidebarItem, UserSidebarItem, getSelectedSidebarItem } from './helpers';

type Props = {
  currentDateAsString: string;
  currentUser: LoggedInProps['currentUser'];
  accountName?: string;
  projectName?: string;
  selectedUserSidebarItem?: UserSidebarItem;
  serverlessDeploymentId?: string;
};

export function SidebarNavigation({
  currentUser,
  currentDateAsString,
  accountName,
  projectName,
  selectedUserSidebarItem,
  serverlessDeploymentId,
}: Props) {
  const router = useRouter();

  const projectSlug = projectName ?? '';

  const urlAccountName = encodeURIComponent(accountName ?? '');
  const account = currentUser.accounts.find((account) => account.name === accountName);

  const selectedSidebarItem = getSelectedSidebarItem(router?.pathname ?? '', SidebarItem.HOME);

  const isSuperuserMode =
    (currentUser.isExpoAdmin &&
      !currentUser.accounts.some((account) => account?.name === accountName)) ??
    false;

  const isPersonalAccountOfSSOUser = account?.ownerUserActor?.__typename === 'SSOUser';

  const accountForLogoHref = accountName ?? currentUser.username;

  const logo = (
    <div className="flex items-center justify-between px-3 pt-3">
      <Logo
        href={accountForLogoHref === currentUser.username ? '/' : `/accounts/${accountForLogoHref}`}
      />
      <div className="flex items-center gap-0.5">
        <DocsButton />
        <NotificationsList popoverClassName="right-[-312px]" />
      </div>
    </div>
  );

  const scrollFadeTop = (
    <div className="relative">
      <div className="pointer-events-none absolute left-0 z-10 h-2 w-[calc(100%-6px)] bg-gradient-to-b from-default to-transparent" />
    </div>
  );

  const userDropdownSection = (
    <div className="sticky bottom-0 max-w-[260px] max-md-gutters:hidden">
      {currentUser && <UserDropdown currentUser={currentUser} />}
    </div>
  );

  return (
    <div className="flex h-full flex-col justify-between bg-default max-md-gutters:min-h-[unset]">
      <div className="sticky top-0 z-10 grid h-screen max-w-[260px] grid-rows-[repeat(3,min-content),1fr,min-content]">
        {logo}
        <div className="flex max-w-[259px] flex-col gap-2 border-b border-secondary pb-3 pt-2">
          {accountName && (
            <NavigationSelectors
              accountName={accountName}
              projectName={projectSlug}
              currentUser={currentUser}
              currentDateAsString={currentDateAsString}
              isSuperuserMode={isSuperuserMode}
            />
          )}
        </div>
        {scrollFadeTop}
        {currentUser && accountName && (
          <div id="sidebar" className="flex flex-col gap-1 overflow-y-auto overflow-x-hidden p-2">
            {!selectedSidebarItem?.startsWith('USER_SETTINGS') ? (
              <SidebarLinks
                selectedSidebarItem={selectedSidebarItem}
                accountName={accountName}
                projectName={projectSlug}
                currentUser={currentUser}
                serverlessDeploymentId={serverlessDeploymentId}
              />
            ) : (
              <UserSettings
                urlAccountName={urlAccountName}
                isSSOUser={isPersonalAccountOfSSOUser}
                selectedSidebarItem={selectedSidebarItem}
                selectedUserSidebarItem={selectedUserSidebarItem}
              />
            )}
          </div>
        )}
        {userDropdownSection}
      </div>
    </div>
  );
}
